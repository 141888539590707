import { Menu } from "./menu.model";

export const menuItems = [
  new Menu(1, "ADMIN_NAV.DASHBOARD", "/", null, "dashboard", null, false, 0),

  new Menu(
    2,
    "Accounting",
    null,
    null,
    "account_balance_wallet",
    null,
    true,
    0
  ),
  new Menu(
    3,
    "Account",
    "/accounting/AccountList",
    null,
    "account_balance_wallet",
    null,
    false,
    2
  ),
  new Menu(
    4,
    "AccountType",
    "/accounting/AccountTypeList",
    null,
    "account_balance_wallet",
    null,
    false,
    2
  ),
  new Menu(
    5,
    "AccountSubType ",
    "/accounting/AccountSubTypeList",
    null,
    "account_balance_wallet",
    null,
    false,
    2
  ),
  new Menu(
    6,
    "AccountClass ",
    "/accounting/AccountClassList",
    null,
    "account_balance_wallet",
    null,
    false,
    2
  ),
  new Menu(
    7,
    "Transaction Type",
    "/accounting/TransactionTypeList",
    null,
    "monetization_on",
    null,
    false,
    2
  ),

  new Menu(8, "Party", null, null, "person", null, true, 0),
  new Menu(
    9,
    "Employee",
    "/party/EmployeeList",
    null,
    "person",
    null,
    false,
    8
  ),
  new Menu(10, "Vendor", "/party/VendorList", null, "Vendor", null, false, 8),

  new Menu(
    11,
    "Customer",
    "/party/CustomerList",
    null,
    "person",
    null,
    false,
    8
  ),
  new Menu(17, "Title", "/party/TitleList", null, "subtitles", null, false, 8),
  // new Menu(
  //   17,
  //   "Bill",
  //   "/party/BillList",
  //   null,
  //   "assignment",
  //   null,
  //   false,
  //   8
  // ),

  new Menu(15, "Bill", "/party/BillList", null, "assignment", null, false, 0),
  new Menu(12, "Journal", null, null, "account_balance_wallet", null, true, 0),
  new Menu(
    13,
    "Journal",
    "/accounting/JournalList",
    null,
    "account_balance_wallet",
    null,
    false,
    12
  ),
  new Menu(
    14,
    "Ex-Rate",
    "/accounting/ExRateList",
    null,
    "account_balance_wallet",
    null,
    false,
    12
  ),
  new Menu(15, "Users", "/user/UserList", null, "person", null, false, 0),
  // new Menu(
  //   12,
  //   "Users",
  //   "/user/UserList",
  //   null,
  //   "person",
  //   null,
  //   false,
  //   11
  // ),
  new Menu(16, "Tenant", "/user/TenantList", null, "person", null, false, 0),
  // new Menu(
  //   14,
  //   "Tenant",
  //   "/user/UserList",
  //   null,
  //   "person",
  //   null,
  //   false,
  //   13
  // ),
];

import { environment } from "src/environments/environment";

export class HttpApi {
  // static BaseUrl = environment.host
  static app_version = "1.0.1/";
  static membershipBaseUrl = "members/";
  // OAuthoauth

  static userRegister = "auth/login";
  static userDestroy = "auth/login";
  static userUpdate = "auth/login";
  static userRead = "auth/login";
  static oauthRefreshToken = "auth/login";
  static plan = "plans/";
  static customer = "customers/";
  static facility = "facilities/";
  static facilityMember = "facilitymembers/";
  static category = "categories/";
  static sale = "sales/";
  static product = "products/";

  static tenantResendEmail = "tenants/resend";

  static users = "auth/register/";
  static Edituser = "auth/edit/";
  // static account = "accounts/";
  // static accountClass = "accountclasses/";
  // static accountType = "accountTypes/";
  // static accountSubType = "accountSubTypes/";
  static role = "roles/";
  static Assignrole = "userroles/";
  static Assignaccount = "accountAssignments/";
  static Branch = "branchs/";
  static Journal = "Journal/";
  // static GetAccount = "accounts/";
  // static GetTranscation = "TransactionTypes/"
  // static GetParty= "Parties/"
  // static subTypp= "accountsubtypes/"
  // static Ty= "accounttype/"

  // static Assignedrole = 'userroles/';

  //new
  static oauthLogin = "users/login";
  static subType = "accountsubtypes/";
  static Type = "accounttype/";
  static tenant = "tenants/";
  static accountclass = "accountclasses/";
  static account = "accounts/";
  static user = "users/register";
  static getUser = "users/";
  static journal = "journal/";
  static ExcelJournal= "journal/importExcel/"
  static ExcelAccount= "accounts/importExcel/"
  static getTransactionType = "TransactionTypes/";
  static party = "parties/";
  static getParty = "parties/byPartyType/";
  static JournalDetail = "JournalDetails/";
  static exchangeRate = "exchangerates/";
  static reset = "users/register/tenantuser";
  static getJournalByDate = "journal/byDateRange/";
  static getJournalById = "journal/OneJournal/byId/";
  static getpartyByTransaction = "parties/byTransactionType/";
  static profile = "profile/"
  static Title= "titles/"
  static Bill= "bills/"
  
}

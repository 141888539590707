import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { userService } from "src/app/services/userManagement.service";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
  public form: UntypedFormGroup;
  public user: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: userService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditProfileComponent>
  ) {}

  ngOnInit(): void {
    // this.loadUserProfile();
    const TenantId = localStorage.getItem("tenant_id");
    const tenant = TenantId?.replace(/['"]+/g, "");
    console.log(TenantId);
    this.form = this.formBuilder.group({
      username: ["", Validators.required],
      firstname: ["", Validators.required],
      lastname: ["", Validators.required],
      mobile: ["", Validators.required],
      address: ["", Validators.required],
      email: ["", Validators.required],
      tenant_id: [tenant],
    });
    if (this.data.user[0]) {
      console.log(this.data.user[0]);
      this.form.patchValue(this.data.user[0]);
    }
  }
  loadUserProfile() {
    const profileusername = localStorage.getItem("username");
    const username = profileusername.replace(/['"]+/g, "");
    console.log(username);

    this.userService.getprofileByID(this.user).subscribe((data) => {
      this.user = data;
      console.log(this.user);
    });
  }
  UpdateUser() {
    if (this.form.valid) {
      const user = this.form.value.username;
      this.userService
        .put(this.form.value, this.data.user[0].username)
        .subscribe({
          next: (res) => {
            this.snackBar.open("Profile Updated Successfully", "", {
              duration: 2500,
              verticalPosition: "bottom",
              horizontalPosition: "left",
            });
            // this.router.navigate(['facility/member/list']);
            this.form.reset();

            this.dialogRef.close("save");
          },
          error: () => {
            alert("error");
          },
        });
    }
  }
}

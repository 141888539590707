import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { HttpApi } from "../core/http/http-api";
import { Tenant } from "../models/tenant";

@Injectable({
  providedIn: "root",
})
export class TenantService {
  constructor(private http: HttpClient) {}

  post(data: any) {
    return this.http.post<any>(HttpApi.tenant, data);
  }
  sendEmail(emailNotification: any) {
    return this.http.post(HttpApi.tenantResendEmail, emailNotification, {
      observe: 'response',
    });
  }
  resetPassword(data: any) {
    return this.http.post<any>(HttpApi.reset, data);
  }
  getAll() {
    return this.http.get<Tenant[]>(HttpApi.tenant);
  }
  put(data: any, id: any) {
    return this.http.put<Tenant>(HttpApi.tenant + id, data);
  }
  delete(id: number) {
    return this.http.delete<any>(HttpApi.tenant + id);
  }
  getById(id: string) {
    return this.http.get<Tenant>(HttpApi.tenant + id);
  }
}

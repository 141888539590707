import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { TenantService } from "src/app/services/tenant.service";

@Component({
  selector: "app-create-password",
  templateUrl: "./create-password.component.html",
  styleUrls: ["./create-password.component.scss"],
})
export class CreatePasswordComponent implements OnInit {
  form!: FormGroup;

  code: any;
  public showPassword: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private tenantService: TenantService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.code = params["id"];
      }
    });
    this.form = this.formBuilder.group({
      // code: [this.code, Validators.required],
      username: ["", Validators.required],
      password: ["", Validators.required],
      email: ["", Validators.required],
      tenant_id: [this.code, Validators.required],
    });
  }
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  changePassword() {
    this.tenantService.resetPassword(this.form.value).subscribe({
      next: (res) => {
        this.snackBar.open("Tenant User Created Successfully!", "", {
          duration: 2500,
          panelClass: ["blue-snackbar"],
          verticalPosition: "bottom",
          horizontalPosition: "left",
        });
        this.router.navigate(["auth/login"]);
      },
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpApi } from '../core/http/http-api';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class userService {
  constructor(private http: HttpClient) { }

  post(data: any) {
    return this.http.post<any>(HttpApi.user, data);
  }
  getAll() {
    
    return this.http.get<User[]>(HttpApi.getUser);
  }
  profile() {
    return this.http.get<User>(HttpApi.profile);
  }
  getprofileByID(id: string) {
    return this.http.get<any>(HttpApi.profile + id);
  }
  put(data: any, username: string) {
    return this.http.put<any>(HttpApi.profile + username, data);
  }
 
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { EditProfileComponent } from "../../accounting/user-management/edit-profile/edit-profile.component";
import { MatDialog } from "@angular/material/dialog";
import { userService } from "src/app/services/userManagement.service";

@Component({
  selector: "app-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnInit {
  public userImage = "assets/images/others/user3.jpg";
  public user: any[] = [];
  constructor(
    private _authService: AuthService,
    private _router: Router,
    public dialog: MatDialog,
    public userService: userService
  ) {}

  ngOnInit(): void {
    this.loadUserProfile();
  }

  loadUserProfile() {
    const usename = localStorage.getItem("username");
    const username = usename.replace(/['"]+/g, "");
    console.log(username);
    this.userService.getprofileByID(username).subscribe((data) => {
      this.user = data;
      console.log(this.user);
    });
  }
  logout() {
    this._authService.logout();
    this._router.navigateByUrl("/auth/login");
  }
  public openUserDialog(user) {
    let dialogRef = this.dialog
      .open(EditProfileComponent, {
        data: {
          user: user,
        },
        width: "40%",
      })
      .afterClosed()
      .subscribe((result) => {
        this.ngOnInit();
      });
  }
}

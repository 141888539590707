import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { NotFoundComponent } from "./shared/not-found/not-found.component";
import { AuthGuard } from "./auth/guards/auth-guard.service";
import { LoginComponent } from "./auth/login/login/login.component";
import { AdminComponent } from "./admin/admin.component";
import { CreatePasswordComponent } from "./admin/accounting/user-management/create-password/create-password.component";
import { AppComponent } from "./app.component";

export const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },

  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
 
    {
    path: "createpassword/:id",
    component: CreatePasswordComponent,
    data: { breadcrumb: "create password"},
  },

  {
    path: "redirect",
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: "reset/:id",
  //   component: ResetPasswordComponent,
  //   data: { breadcrumb: "reset password " },
  // },
  { path: "Home", component: AppComponent },
  { path: "**", component: NotFoundComponent },
  
 
 
  // {
  //   path: "facility/reset/:id",
  //   loadChildren: () =>
  //     import("./admin/facility/facility.module").then((m) => m.FacilityModule),
  // },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: "legacy",
      initialNavigation: "enabledBlocking", // for one load page, without reload
       useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
